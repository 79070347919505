// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/open-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".input-search>input{padding:15px 30px 15px 20px}.input-search:before{content:\"\";background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;position:absolute;right:15px;display:block;width:15px;height:8px;top:23px;transition:all .3s ease-in-out;transform:rotate(0deg)}.input-search--active>input{border-radius:15px 15px 0 0;border-bottom:none}.input-search--active:before{transform:rotate(180deg);top:20px}.input-search__results{overflow:hidden;border-radius:0 0 15px 15px;display:block;position:absolute;width:100%;z-index:10;border:2px solid var(--btn-blue-color1);border-top:none}.input-search__results-list{max-height:200px;overflow-y:auto;position:relative;z-index:1;scrollbar-color:var(--main-color) var(--input-color);scrollbar-width:thin;background:var(--input-color)}.input-search__results-list::-webkit-scrollbar{width:5px;height:5px;background-color:var(--input-color)}.input-search__results-list::-webkit-scrollbar-thumb{background-color:var(--main-color)}.input-search__results-list span{display:block;padding:10px 15px}.input-search__results-item{font-weight:400;font-size:16px;transition:all .3s ease-in-out;cursor:pointer;position:unset;padding:8px 15px;display:block;transform:unset}.input-search__results-item:hover{background-color:var(--btn-gray-color1);color:var(--white)}.input-search--error{position:relative}.input-search--error input{border-color:var(--red)}.input-search--error .form__error{color:var(--red);top:calc(100% - 15px);font-size:12px;line-height:14px;font-weight:600}", ""]);
// Exports
module.exports = exports;
