<template>
  <div
    class="input input-search"
    :class="[className, { 'input-search--active': listSearchShow }]"
    v-click-away="
      () => {
        listSearchShow = false;
      }
    "
  >
    <input
      :type="type"
      :id="uniqueId"
      v-model.trim="_value"
      :class="_value ? 'is-focused' : ''"
      :disabled="disabled"
      v-maska="mask"
      v-bind="$attrs"
      @click="toggleDropdown"
      @input="
        () => {
          _value = $refs.input.value;
          listSearchShow = true;
        }
      "
      ref="input"
    />
    <label :for="uniqueId">{{ label }}</label>
    <div class="input-search__results" v-if="listSearchShow">
      <div class="input-search__results-list" v-if="filteredList.length === 0">
        <span>Совпадения не найдены</span>
      </div>
      <div class="input-search__results-list" v-else>
        <div
          class="input-search__results-item"
          v-for="item in filteredList"
          :key="item?.region_id"
          @click="select(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import { maska } from "maska";
import { mixin as VueClickAway } from "vue3-click-away";

export default {
  name: "InputSearch",
  mixins: [VueClickAway],
  directives: { maska },
  data() {
    return {
      uniqueId: uniqueString(),
      uniqComponentId: uniqueString(),
      listSearchShow: false,
      filteredList: [],
      test: "",
    };
  },
  props: {
    label: String,
    modelValue: [String, Number],
    type: String,
    error: Boolean,
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorArray: Array,
    mask: String,
    resultsArray: Array,
  },
  computed: {
    _value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "input-search--error": this.error,
        "input--disabled": this.disabled,
      };
    },
  },
  watch: {
    resultsArray(value) {
      this.filteredList = this.resultsArray;
    },
    _value: {
      handler: function (value) {
        if (!value || !/[^\s]+/.test(value)) {
          this.filteredList = this.resultsArray;
        } else {
          this.filteredList = this.resultsArray.filter((post) => {
            return post.name?.toLowerCase().includes(value?.toLowerCase());
          });
        }
      },
    },
  },
  methods: {
    select(item) {
      this._value = item.name;
      this.listSearchShow = false;
      this.$emit("form-item", item);
    },
    toggleDropdown() {
      this.listSearchShow = !this.listSearchShow;
    },
    uniqueString,
  },
};
</script>

<style lang="scss">
.input-search {
  & > input {
    padding: 15px 30px 15px 20px;
  }
  &::before {
    content: "";
    background-image: url("~@/assets/img/open-arrow.svg");
    background-size: contain;
    position: absolute;
    right: 15px;
    display: block;
    width: 15px;
    height: 8px;
    top: 23px;
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg);
  }
  &--active {
    & > input {
      border-radius: 15px 15px 0 0;
      border-bottom: none;
    }
    &::before {
      transform: rotate(180deg);
      top: 20px;
    }
  }
  &__results {
    overflow: hidden;
    border-radius: 0 0 15px 15px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 10;
    border: 2px solid var(--btn-blue-color1);
    border-top: none;
    &-list {
      max-height: 200px;
      overflow-y: auto;
      position: relative;
      z-index: 1;
      @include scrollbar();
      background: var(--input-color);
      span {
        display: block;
        padding: 10px 15px;
      }
    }
    &-item {
      font-weight: 400;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      position: unset;
      padding: 8px 15px;
      display: block;
      transform: unset;
      &:hover {
        background-color: var(--btn-gray-color1);
        color: var(--white);
      }
    }
  }
  &--error {
    position: relative;
    input {
      border-color: var(--red);
    }
    .form__error {
      color: var(--red);
      top: calc(100% - 15px);
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
  }
}
</style>
