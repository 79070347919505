<template>
  <div class="container">
    <div class="lk-about" v-if="!isLoadingData">
      <div class="lk-about__main">
        <div class="lk-about__form">
          <div class="lk-about__name">
            <Input
              class="formData_last_name"
              label="Фамилия*"
              type="text"
              v-model="formData.last_name"
              :error-array="v$.formData.last_name.$errors"
              @blur="v$.formData.last_name.$touch"
              :error="
                v$.formData.last_name.$errors !== 0 &&
                v$.formData.last_name.$errors[0]
              "
            />
            <Input
              class="formData_first_name"
              label="Имя*"
              type="text"
              v-model="formData.first_name"
              :error-array="v$.formData.first_name.$errors"
              @blur="v$.formData.first_name.$touch"
              :error="
                v$.formData.first_name.$errors !== 0 &&
                v$.formData.first_name.$errors[0]
              "
            />
            <Input
              class="formData_middle_name"
              label="Отчество"
              type="text"
              v-model="formData.middle_name"
              :error-array="v$.formData.middle_name.$errors"
              @blur="v$.formData.middle_name.$touch"
              :error="
                v$.formData.middle_name.$errors !== 0 &&
                v$.formData.middle_name.$errors[0]
              "
            />
          </div>
          <div class="lk-about__birth">
            <Input
              class="formData_birthday"
              label="День рождения*"
              type="date"
              :min="maxBirthYear"
              :max="minBirthYear"
              v-model.lazy="formData.birthday"
              @focusout="validateBirth($event)"
              contenteditable="true"
              pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
              :error-array="v$.formData.birthday.$errors"
              @blur="v$.formData.birthday.$touch"
              :error="
                v$.formData.birthday.$errors !== 0 &&
                v$.formData.birthday.$errors[0]
              "
            />
            <div class="lk-about__sex">
              <p>Пол*</p>
              <Dropdown
                class="formData_gender"
                :items="Object.keys(listGender)"
                :keys="Object.values(listGender)"
                v-model="formData.gender"
                :start-value="'Пол'"
                :error-array="v$.formData.gender.$errors"
                @blur="v$.formData.gender.$touch"
                :error="
                  v$.formData.gender.$errors !== 0 &&
                  v$.formData.gender.$errors[0]
                "
              />
            </div>
          </div>
          <div class="lk-about__phone">
            <Input
              class="formData_phone"
              label="Номер телефона*"
              type="tel"
              :mask="'+7(###)-###-##-##'"
              v-model="formData.phone"
              :error-array="v$.formData.phone.$errors"
              @blur="v$.formData.phone.$touch"
              :error="
                v$.formData.phone.$errors !== 0 && v$.formData.phone.$errors[0]
              "
            />
          </div>
          <div class="lk-about__socials">
            <Input
              label="VK https://vk.com"
              type="text"
              v-model="formData.socials[0].href"
              @blur="validateVk($event)"
              :error-text="vkErrorText"
              :error="!!vkErrorText"
              class="formData_vk"
            />
            <p>
              Пожалуйста, не закрывайте доступ к личным сообщениям в
              прикрепленных Вами социальных сетях на время действия заявочной
              кампании.
            </p>
          </div>
          <div class="lk-about__university">
            <p>Учебное заведение*</p>

            <RadioBtnsText
              class="formData_info_university_type"
              v-model="formData.info_university.type"
              :items="['vuz', 'suz']"
              :keys="['ВУЗ', 'СПО']"
              :text="[
                'Высшее учебное заведение — образовательное учреждение, осуществляющее подготовку специалистов с высшим профессиональным образованием.',
                'Среднее профессиональное образование — средний уровень профессионального образования. В настоящее время среднее профессиональное образование может быть получено в техникумах и колледжах.',
              ]"
              @click="changeVuzValue"
              @update:modelValue="
                () => {
                  this.formData.info_university.id = null;
                  this.formData.info_university.name = null;
                  if (this.formData.info_university.type === 'suz') {
                    this.formData.faculty = '---';
                  } else this.formData.faculty = '';
                }
              "
              :disabled="infoStatements"
              university
              :error-array="v$.formData.info_university.type.$errors"
              :error="
                v$.formData.info_university.type.$errors !== 0 &&
                v$.formData.info_university.type.$errors[0]
              "
            />
          </div>
          <div class="lk-about__university-block">
            <p>Город и учебное заведение</p>
            <div class="lk-about__region">
              <InputSearch
                class="formData_info_region_name"
                label="Регион*"
                v-model="formData.info_region.name"
                :results-array="listRegions"
                @form-item="setIdRegion"
                :error-array="v$.formData.info_region.name.$errors"
                @blur="v$.formData.info_region.name.$touch"
                :error="
                  v$.formData.info_region.name.$errors !== 0 &&
                  v$.formData.info_region.name.$errors[0]
                "
              />
              <InputSearch
                class="formData_info_region_id"
                label="Город*"
                type="text"
                :disabled="!formData.info_region.region_id"
                v-model="formData.info_city.name"
                :results-array="listCitiesFiltered"
                @form-item="setIdCity"
                :error-array="v$.formData.info_city.name.$errors"
                @blur="v$.formData.info_city.name.$touch"
                :error="
                  v$.formData.info_city.name.$errors !== 0 &&
                  v$.formData.info_city.name.$errors[0]
                "
              />
            </div>
            <div class="lk-about__university-info">
              <InputSearch
                class="formData_info_university_name"
                label="Образовательное учреждение*"
                type="text"
                v-model="formData.info_university.name"
                :results-array="listUniversitiesResult"
                :disabled="noVuz || !formData.info_university.type"
                @form-item="setIdUniversity"
                :error-array="v$.formData.info_university.name.$errors"
                @blur="v$.formData.info_university.name.$touch"
                :error="
                  v$.formData.info_university.name.$errors !== 0 &&
                  v$.formData.info_university.name.$errors[0]
                "
              />
              <Input
                v-if="formData.info_university.type !== 'suz'"
                class="formData_faculty"
                label="Институт/факультет*"
                type="text"
                v-model="formData.faculty"
                :error-array="v$.formData.faculty.$errors"
                @blur="v$.formData.faculty.$touch"
                :error="
                  v$.formData.faculty.$errors !== 0 &&
                  v$.formData.faculty.$errors[0]
                "
              />
              <Input
                class="formData_group"
                label="Номер группы*"
                type="text"
                v-model="formData.group"
                :error-array="v$.formData.group.$errors"
                @blur="v$.formData.group.$touch"
                :error="
                  v$.formData.group.$errors !== 0 &&
                  v$.formData.group.$errors[0]
                "
              />
              <RadioBtnToggle
                v-model="noVuz"
                :label="'Не нашёл своё образовательное учреждение'"
              />
            </div>
          </div>
          <div class="lk-about__tg" v-if="telegramLink !== null">
            <p>
              Привяжите свой телеграм аккаунт для оповещения прохождения отбора
            </p>
            <span>
              Сохранение данных возможно после привязки telegram-бота
            </span>
            <Button red>
              <a
                :href="$store.state.user.user.telegram_link"
                target="_blank"
                @click="test = false"
              >
                ПЕРЕЙТИ К БОТУ
              </a>
            </Button>
          </div>

          <div class="lk-about__tg" v-if="!telegramLink">
            <Button small red @click="telegramUnlink" :loading="isTgLoading">
              ОТВЯЗАТЬ ТЕЛЕГРАМ
            </Button>
          </div>
        </div>
        <div class="lk-about__image">
          <CropImageUpload
            class="cropImg"
            v-model="cropImg"
            :error-array="v$.cropImg.$errors"
            :error="v$.cropImg.$errors !== 0 && v$.cropImg.$errors[0]"
          />

          <div class="lk-about__img-name">
            {{ $store.state.user.user.first_name }}
            {{ $store.state.user.user.last_name }}
          </div>
          <InputFile
            name="Изменить фото"
            @loadingFile="cropImg = this.$store.state.dontSavedCrop"
          />
          <Input
            label="ID"
            type="text"
            v-model="$store.state.user.user.id"
            readonly
            disabled
          />
          <Input
            label="Email"
            type="text"
            v-model="$store.state.user.user.email"
            readonly
            disabled
          />
        </div>
      </div>
      <div v-if="errors?.length !== 0" class="lk-about__errors">
        <span v-for="(item, index) in errors" :key="index">
          {{ item.error_descr }}
        </span>
      </div>
      <Button
        red
        @click="handleFormSubmit"
        :disabled="
          (formIncorrect || !formChanged || telegramLink !== null) && test
        "
        :loading="isLoading"
      >
        СОХРАНИТЬ
      </Button>
      <div class="data-saved" v-if="dataSaved">Данные успешно сохранены</div>
      <a @click="goToAnchor(firstError)" v-if="firstError" class="to-error">
        Обратите внимание на заполнение полей.
      </a>
      <a
        @click="goToAnchor('formData.vk')"
        v-else-if="vkErrorText"
        class="to-error"
      >
        Некорректная ссылка на профиль Вконткте
      </a>
    </div>
    <Preloader v-else />
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import Dropdown from "@/components/Blocks/Dropdown";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
const { mapGetters, mapState } = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import CropImageUpload from "@/components/Blocks/CropImageUpload";
import InputSearch from "@/components/Blocks/InputSearch";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import RadioBtnsText from "@/components/Blocks/RadioBtnsText";
import RadioBtnToggle from "@/components/Blocks/RadioBtnToggle";
import requestConfigs from "@/api/requestConfigs";
import Preloader from "@/components/Blocks/Preloader";
import moment from "moment";
import store from "@/store";
import InputFile from "@/components/Blocks/InputFile";

export default {
  name: "LkAbout",
  components: {
    InputFile,
    Preloader,
    RadioBtnToggle,
    RadioBtnsText,
    InputSearch,
    CropImageUpload,
    Button,
    Dropdown,
    Input,
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isTgLoading: false,
      value: undefined,
      infoStatements: false,
      test: true,
      isDataLoaded: false,
      isLoadingData: false,
      noVuz: false,
      timeout: null,
      telegramLink: null,
      formChanged: false,
      formIncorrect: false,
      dataSaved: false,
      avatar_update: false,
      allParam: {},
      listCitiesFiltered: [],
      listUniversitiesFiltered: [],
      isLoading: false,
      cropImg: null,
      errors: [],
      formData: {
        first_name: "",
        middle_name: "",
        last_name: "",
        birthday: "",
        phone: "9111111111",
        city_name: "",
        info_region: {
          name: "",
          region_id: null,
        },
        info_city: {
          name: "",
          city_id: null,
          region_id: null,
        },
        info_university: {
          name: "",
          id: null,
          type: "",
        },
        faculty: "",
        group: null,
        gender: "",
        avatar:
          this.$store.state.user.user.avatar ??
          require("@/assets/img/avatar.png"),
        socials: [
          {
            type: "vk",
            href: "",
          },
        ],
      },
      dataIsLoaded: false,
      vkErrorText: "",
    };
  },
  validations() {
    return {
      formData: {
        birthday: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        gender: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        last_name: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 1",
            minLength(1)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 20",
            maxLength(20)
          ),
        },
        first_name: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 1",
            minLength(1)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 20",
            maxLength(20)
          ),
        },
        middle_name: {
          minLength: helpers.withMessage(
            "Количество символов не менее 1",
            minLength(1)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 20",
            maxLength(20)
          ),
        },
        phone: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        faculty: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 100",
            maxLength(100)
          ),
        },

        group: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 10",
            maxLength(10)
          ),
        },
        info_region: {
          name: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
        },
        info_city: {
          name: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
        },
        info_university: {
          name: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
          type: {
            required: helpers.withMessage("Обязательно к заполнению", required),
          },
        },
        // avatar: {
        //   required:
        //     this.$store.state.user.user.avatar === null
        //       ? helpers.withMessage("Необходимо загрузить фото", required)
        //       : "",
        // },
      },
      cropImg: {
        required:
          this.$store.state.user.user.avatar === null
            ? helpers.withMessage("Необходимо загрузить фото", required)
            : "",
      },
    };
  },
  watch: {
    formData: {
      handler: function () {
        this.formChanged = true;
        this.$store.state.dataSaved = false;
      },
      deep: true,
    },
    cropImg() {
      this.avatar_update = true;
      this.formChanged = true;
    },
    avatar_update() {
      if (this.avatar_update === false) {
        this.formData.avatar = null;
      }
    },
    "formData.info_region.name"() {
      this.listCitiesFiltered = this.listCities?.filter((item) => {
        return item.region_id === this.formData.info_region.region_id;
      });
    },
    "formData.info_region.region_id"() {
      if (
        this.formData.info_city.region_id !==
        this.formData.info_region.region_id
      ) {
        this.formData.info_city.city_id = null;
        this.formData.info_city.name = null;
      }
    },
    "formData.info_university.type"(value) {
      this.listUniversitiesFiltered = this.listUniversities?.filter((item) => {
        return item.type === value;
      });
    },
    noVuz() {
      if (this.noVuz === true) {
        this.formData.info_university.id = null;
        this.formData.info_university.name = null;
      }
    },
    "v$.$errors"() {
      this.v$.$errors.length !== 0
        ? (this.formIncorrect = true)
        : (this.formIncorrect = false);
    },
    "formData.socials": {
      handler: function () {
        this.$store.commit("user/setUserSocials", this.formData.socials);
      },
      deep: true,
    },
  },
  computed: {
    firstError() {
      return this.v$.$errors[0]?.$propertyPath;
    },
    listGender() {
      return this.allParam.list_gender;
    },
    listRegions() {
      return this.allParam.list_regions;
    },
    listCities() {
      return this.allParam.list_city;
    },
    listUniversities() {
      return this.allParam.list_educational_establishments;
    },
    minBirthYear() {
      const year = moment().subtract(16, "year");
      return moment(year).format("YYYY-MM-DD");
    },
    maxBirthYear() {
      const year = moment().subtract(28, "year");
      return moment(year).format("YYYY-MM-DD");
    },
    listUniversitiesResult() {
      return this.listUniversitiesFiltered?.length === 0
        ? this.listUniversities
        : this.listUniversitiesFiltered;
    },

    ...mapGetters(["userProps", "userAvatarSrc"]),
    ...mapState(["user"]),
  },
  methods: {
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || !!this.vkErrorText)
        return (this.formIncorrect = true);
      this.isLoading = true;
      let MY_OBJECT = {
        first_name: this.formData.first_name,
        middle_name: this.formData.middle_name,
        last_name: this.formData.last_name,
        birthday: this.formData.birthday,
        phone: this.formData.phone,
        region_id: this.formData.info_region.region_id,
        city_id: this.formData.info_city.city_id,
        educational_establishment_id: this.formData.info_university.id,
        faculty: this.formData.faculty,
        group: this.formData.group,
        gender: this.formData.gender,
      };
      const payload = Object.keys(MY_OBJECT)
        .filter((f) => MY_OBJECT[f] !== undefined && MY_OBJECT[f] !== null)
        .reduce((r, i) => {
          r[i] = MY_OBJECT[i];
          return r;
        }, {});

      let payloadObject = {};
      payloadObject = new FormData();
      Object.keys(payload).forEach((key) => {
        payloadObject.append(key, payload[key]);
      });
      if (this.avatar_update === true) {
        payloadObject.append(
          "avatar",
          this.$store.state.currentPopup.props.cropImgPOST
        );
      }

      const sendData = {};

      this.formData.socials.forEach((item) => {
        if (item.href) {
          sendData[item.type] = {};
          sendData[item.type]["href"] = item.href;
          sendData[item.type]["type"] = item.type;
          for (let key in sendData) {
            if (typeof sendData[key] === "object") {
              for (let subKey in sendData[key]) {
                payloadObject.append(
                  `socials[${key}][${subKey}]`,
                  sendData[key][subKey]
                );
              }
            } else {
              payloadObject.append(`socials[${key}]`, sendData[key]);
            }
          }
        }
      });

      createRequest(requestConfigs.POSTSaveStudentInfo, {
        jsonPayload: payloadObject,
      })
        .then(() => {
          this.dataSaved = true;
          this.timeout = setTimeout(() => {
            this.dataSaved = false;
          }, 5000);
          this.$store.dispatch("user/getUserInfo");
          this.$router.push({ name: "LkRequest" });
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.errors;
        });
    },
    setIdRegion(item) {
      this.formData.info_region.region_id = item.region_id;
      this.formData.info_city.city_id = null;
    },
    setIdCity(item) {
      this.formData.info_city.city_id = item.city_id;
    },
    setIdUniversity(item) {
      this.formData.info_university.id = item.id;
    },
    changeVuzValue() {
      if (this.formData.info_university.type !== null && !this.infoStatements) {
        this.formData.info_university.id = null;
        this.formData.info_university.name = null;
      }
    },
    goToAnchor(refName) {
      let str = refName;
      let result = str.split(".").join("_");
      let elementPosition = document.querySelector(`.${result}`).offsetTop;
      window.scrollTo({
        top: elementPosition - 20,
        behavior: "smooth",
      });
    },
    validateBirth(e) {
      if (moment(e.target.value) < moment(this.maxBirthYear)) {
        e.target.value = this.maxBirthYear;
      }
      if (moment(e.target.value) > moment(this.minBirthYear)) {
        e.target.value = this.minBirthYear;
      }
    },
    validateVk(e) {
      if (e.target.value && !e.target.value.includes("https://vk.com/")) {
        this.vkErrorText = 'Поле должно быть формата "https://vk.com/"';
      } else this.vkErrorText = "";
    },
    telegramUnlink() {
      this.isTgLoading = true;

      createRequest(requestConfigs.GETUnlinkTg)
        .then((response) => {
          this.$store.state.user.user.telegram_link =
            response.data.telegram_link;
          this.telegramLink = response.data.telegram_link;
        })
        .catch((error) => {
          this.errors = error.errors;
        })
        .finally(() => {
          this.isTgLoading = false;
        });
    },
  },
  beforeMount() {
    if (
      JSON.stringify(this.$store.state.storage.storageStatementParamsData) ===
      "{}"
    ) {
      this.isLoadingData = true;
      createRequest(requestConfigs.GETAllParams)
        .then((result) => {
          this.$store.commit("setStatementParamsData", result.data);
          this.allParam = this.$store.state.storage.storageStatementParamsData;
        })
        .finally(() => {
          this.isLoadingData = false;
          this.listUniversitiesFiltered = this.listUniversities?.filter(
            (item) => {
              return item.type === this.formData.info_university.type;
            }
          );
        });
    } else {
      this.allParam = this.$store.state.storage.storageStatementParamsData;
    }
  },
  mounted() {
    Promise.all([this.$store.dispatch("user/getUserInfo")])
      .then(() => {
        this.telegramLink = this.$store.state.user.user.telegram_link;
        this.formData.avatar = this.$store.state.user.user.avatar;
        this.formData.first_name = this.$store.state.user.user.first_name;
        this.formData.middle_name = this.$store.state.user.user.middle_name;
        this.formData.last_name = this.$store.state.user.user.last_name;
        this.formData.birthday = moment(
          new Date(this.$store.state.user.user.birthday ?? "2000-01-01")
        ).format("YYYY-MM-DD");
        this.formData.phone = this.$store.state.user.user.phone;
        this.formData.faculty = this.$store.state.user.user.faculty;
        this.formData.group = this.$store.state.user.user.group;
        this.formData.gender = this.$store.state.user.user.gender;
        this.formData.info_city.name =
          this.$store.state.user.user.info_city?.name;
        this.formData.info_city.region_id =
          this.$store.state.user.user.info_city?.region_id;
        this.formData.info_city.city_id =
          this.$store.state.user.user.info_city?.city_id;
        this.formData.info_university.name =
          this.$store.state.user.user.info_university?.name;
        this.formData.info_university.id =
          this.$store.state.user.user.info_university?.id;
        this.formData.info_university.type =
          this.$store.state.user.user.info_university?.type;
        this.formData.info_region.name =
          this.$store.state.user.user.info_region?.name;
        this.formData.info_region.region_id =
          this.$store.state.user.user.info_region?.region_id;
        this.formData.socials[0].href =
          !this.$store.state.user.user.socials?.data.find((item) => {
            return item.type === "vk";
          })?.href
            ? (this.formData.socials[0].href = null)
            : (this.formData.socials[0].href =
                this.$store.state.user.user.socials?.data.find((item) => {
                  return item.type === "vk";
                }).href);
      })
      .then(() => {
        this.$store.state.user.user.info_statements?.data.length === 0
          ? (this.infoStatements = false)
          : (this.infoStatements = true);
        this.formChanged = false;
        this.$store.state.dataSaved = true;
      });
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$store.state.dataSaved && !this.dataSaved) {
      this.$store.state.nextRoute = to.name;
      Promise.all([store.dispatch("openSavedPopup")]);
    } else next();
  },
};
</script>

<style lang="scss">
.lk-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 150px;
    width: 100%;
    margin-bottom: 50px;
  }
  &__form {
    flex-grow: 1;
  }
  &__image {
    max-width: 440px;
    width: 100%;
    border-radius: 15px;
    background-color: var(--btn-blue-color1);
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    button {
      border-color: var(--white);
      &:hover {
        background-color: var(--white);
        color: var(--black2);
      }
    }
    .file {
      .btn {
        width: 100%;
        border-color: var(--white);
        text-align: center;
        &:hover {
          background-color: var(--white);
          color: var(--black2);
        }

        padding: 0;
      }
      &__label {
        width: 100%;
        display: block;
        padding: 5px 20px;
      }
    }
    input {
      background-color: #f0f0f0;
      border-color: #f0f0f0;
      color: var(--btn-gray-color1);
    }
  }
  &__img {
    &-form {
      display: block;
      height: 390px;
      border: 2px solid var(--white);
      border-radius: 15px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &-name {
      text-align: center;
      color: var(--white);
      font-weight: 600;
      @include text30();
    }
  }
  &__name {
    display: flex;
    gap: 20px 40px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    justify-content: space-between;
    .input {
      flex-grow: 1;
    }
  }
  &__birth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    .input {
      flex-grow: 1;
    }
  }
  &__sex {
    display: flex;
    align-items: center;
    flex-grow: 1;
    p {
      color: var(--btn-gray-color1);
      margin-right: 30px;
      font-size: 16px;
      line-height: 23px;
    }
    .dropdown {
      width: 100%;
      z-index: 3;
    }
  }
  &__phone {
    margin-bottom: 40px;
  }
  &__university {
    p {
      @include text20();
      color: var(--black2);
      margin-bottom: 20px;
    }
    margin-top: 40px;
    &-block {
      margin-top: 40px;
      p {
        @include text20();
        color: var(--black2);
        margin-bottom: 20px;
      }
    }
  }
  &__region {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    .input-search {
      flex-grow: 1;
    }
  }
  &__university-info {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
    .input {
      flex-grow: 1;
      &:first-child {
        width: 100%;
      }
    }
  }
  &__socials {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    .input {
      flex-grow: 1;
    }
    &-block {
      margin-top: 80px;
      p {
        @include text20();
        color: var(--black2);
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 15px;
      line-height: 18px;
    }
  }
  &__tg {
    margin-top: 40px;
    p {
      @include text20();
      font-weight: 600;
      color: var(--black2);
      margin-bottom: 20px;
    }
    & > span {
      margin-bottom: 15px;
      display: inline-block;
    }
  }
  &__errors {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: var(--red);
    }
  }
  @include adaptive(desktop-small) {
    &__main {
      gap: 50px;
    }
    &__socials {
      flex-direction: column;
    }
  }
  @include adaptive(tablet-big) {
    &__main {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__image {
      max-width: unset;
      img {
        max-width: 390px;
        margin: 0 auto;
      }
    }
  }
  @include adaptive(tablet-small) {
    margin: 0 -20px;
    &__form {
      padding: 0 20px;
    }
    &__tg {
      margin-top: 45px;
      button {
        margin: 0 auto;
      }
    }
    &__name {
      gap: 10px;
      margin-bottom: 10px;
    }
    &__birth {
      gap: 10px;
      margin-bottom: 10px;
    }
    &__university {
      margin-top: 45px;
      &-block {
        margin-top: 40px;
      }
      &-info {
        margin-top: 10px;
        gap: 10px;
      }
    }
    &__region {
      gap: 10px;
    }
    &__socials {
      gap: 10px;
      flex-direction: column;
      &-block {
        margin-top: 45px;
      }
    }
    &__image {
      border-radius: unset;
      margin: 0 -20px;
    }
    &__sex {
      p {
        display: none;
      }
    }
  }
}
.crop-image {
  img {
    display: block;
    border: 2px solid var(--white);
    border-radius: 15px;
    overflow: hidden;
    height: 390px;
    object-fit: cover;
    width: 100%;
  }
  .form__error {
    color: var(--yellow);
    margin-top: 5px;
  }
  @include adaptive(phone) {
    img {
      max-height: 270px;
    }
  }
}
</style>
