<template>
  <div class="radio-btns">
    <div class="radio-btns__list">
      <div class="radio-btns__item" :class="className">
        <input
          :id="`radio_${uniqComponentId}`"
          type="checkbox"
          v-model="value"
          v-bind="$attrs"
        />
        <label :for="`radio_${uniqComponentId}`">{{ label }}</label>
        <SmoothHeight>
          <div class="radio-btns__text" v-if="value === true">
            Напиши в телеграм бот
            <a href="https://t.me/liga_forum_support" target="_blank">
              @liga_forum_support
            </a>
            и мы добавим ваше образовательное учреждение
          </div>
        </SmoothHeight>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import SmoothHeight from "@/components/Blocks/SmoothHeight";

export default {
  name: "RadioBtnToggle",
  components: { SmoothHeight },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    uniqComponentId: uniqueString(),
  },
  props: {
    modelValue: Boolean,
    university: Boolean,
    text: String,
    label: String,
  },
};
</script>

<style scoped></style>
