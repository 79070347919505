<template>
  <div class="crop-image">
    <img
      :src="
        $store.state.dontSavedCrop ??
        ($store.state.user.user.avatar || require('@/assets/img/avatar.png'))
      "
      alt="Аватар пользователя"
    />
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorArray: Array,
    error: Boolean,
  },
  computed: {
    className() {
      return {
        "crop-image--error": this.error,
      };
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.crop-image {
  img {
    display: block;
    border: 2px solid var(--white);
    border-radius: 15px;
    overflow: hidden;
    height: 390px;
    object-fit: cover;
    width: 100%;
  }
  .form__error {
    color: var(--yellow);
    margin-top: 5px;
    font-size: 22px;
    font-weight: 600;
  }
  @include adaptive(phone) {
    img {
      max-height: 270px;
    }
  }
}
.vue-image-crop-upload {
  display: flex;
  padding: 10px;
  .vicp-wrap {
    max-width: 600px;
    width: 100%;
    max-height: 320px;
    height: unset;
    border-radius: 5px;
    position: relative;
    .vicp-drop-area {
      height: unset !important;
    }
    .vicp-step2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .vicp-crop {
      overflow: unset;
    }
    .vicp-crop-left {
      float: unset !important;
      display: flex;
      justify-content: center;
    }
    .vicp-crop-right {
      display: none;
    }
    .vicp-range {
      display: none;
    }
    .vicp-operate {
      position: unset;
      display: flex;
      justify-content: center;
      a {
        float: unset;
      }
    }
    .vicp-close {
      right: 0;
      top: 0;
      .vicp-icon4 {
        &::before,
        &::after {
          background-color: #000;
        }
      }
    }
  }
}
</style>
